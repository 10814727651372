import React from "react";
import * as ToastPrimitive from '@radix-ui/react-toast';
import {ProductRenderDisplayVariant} from "../importer/customizer/utils/get-variants-for-display";
import {keyframes} from "@emotion/react";
import {colors} from "../components/primitives/colors";
import Typography from "../components/primitives/typography";
import {useCompareContext} from "./compare-context";
import {breakpoints} from "../components/primitives/tokens";
import {Link} from "../../plugins/gatsby-plugin-atollon";


interface ToastContext {
    createCartToast: (product: ProductRenderDisplayVariant) => void
    createCompareToast: (product: ProductRenderDisplayVariant) => void
    createFreeSampleToast: (product: ProductRenderDisplayVariant) => void
    createGenericToast: (productTitle: string, fabricTitle: string, colorTitle: string, actionText: string) => void
}

interface Toast {
    type: "CartToast" | "CompareToast" | "FreeSampleToast" | "GenericToast"
    variant: ProductRenderDisplayVariant
    productTitle?: string
    fabricTitle?: string
    colorTitle?: string
    actionText?: string
}

const hide = keyframes({
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
});

const slideIn = keyframes({
    from: { transform: `translateX(calc(100% + 20px))` },
    to: { transform: 'translateX(0)' },
});

const swipeOut = keyframes({
    from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    to: { transform: `translateX(calc(100% + 20px))` },
});


const Ctx = React.createContext<ToastContext>(null as unknown as ToastContext)

const ToastContext = ({ children }: React.PropsWithChildren) => {

    const [toasts, _setToasts] = React.useState<Toast[]>([])

    const {
        setIsOpen,
    } = useCompareContext()

    const createCartToast = React.useCallback((product: ProductRenderDisplayVariant) => {
        _setToasts([...toasts, {
            type: "CartToast",
            variant: product
        }])
    }, [toasts])

    const createCompareToast = React.useCallback((product: ProductRenderDisplayVariant) => {
        _setToasts([...toasts, {
            type: "CompareToast",
            variant: product
        }])
    }, [toasts])

    const createFreeSampleToast = React.useCallback((product: ProductRenderDisplayVariant) => {
        _setToasts([...toasts, {
            type: "FreeSampleToast",
            variant: product
        }])
    }, [toasts])

    const createGenericToast = React.useCallback((productTitle, fabricTitle, colorTitle, actionText) => {
        _setToasts([...toasts, {
            type: "GenericToast",
            fabricTitle,
            productTitle,
            colorTitle,
            actionText,
        }])
    }, [toasts])


    const value = React.useMemo(() => ({
        createCartToast,
        createCompareToast,
        createFreeSampleToast,
        createGenericToast,
    }), [
        createCartToast,
        createCompareToast,
        createFreeSampleToast,
        createGenericToast,
    ])

    return (
        <ToastPrimitive.Provider swipeDirection="right">
            <Ctx.Provider value={value}>
                {children}
            </Ctx.Provider>
            {toasts.map((toast, idx) => (
                <ToastPrimitive.Root duration={3000} css={{
                    width: '100%',
                    backgroundColor: (() => {
                        if (toast.type === "CompareToast") {
                            return colors.tertiaryBlue2
                        }
                        return colors.primaryOrange
                    })(),
                    color: (() => {
                        if (toast.type === "CompareToast") {
                            return colors.neutral9
                        }
                        return colors.shadesWhite
                    })(),
                    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
                    borderRadius: '8px',
                    padding: '18px',
                    '&[data-state="open"]': {
                        animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
                    },
                    '&[data-state="closed"]': {
                        animation: `${hide} 100ms ease-in forwards`,
                    },
                    '&[data-swipe="move"]': {
                        transform: 'translateX(var(--radix-toast-swipe-move-x))',
                    },
                    '&[data-swipe="cancel"]': {
                        transform: 'translateX(0)',
                        transition: 'transform 200ms ease-out',
                    },
                    '&[data-swipe="end"]': {
                        animation: `${swipeOut} 100ms ease-out forwards`,
                    },
                }} key={idx}>
                    <ToastPrimitive.Title>
                        <Typography fontSize={{
                            dt: "h4"
                        }} fontWeight="medium">
                            {toast.fabricTitle || toast.variant.fabricTitle}
                        </Typography>
                    </ToastPrimitive.Title>
                    <Typography fontSize={{
                        dt: "h4"
                    }} fontWeight="light">
                        {toast.type === "FreeSampleToast" ? "Free Sample" : (toast.productTitle || toast.variant.productTitle)}
                    </Typography>
                    <Typography fontSize={{
                        dt: "p1"
                    }} css={{
                        marginTop: '4px'
                    }}>
                        {toast.colorTitle || toast.variant.colorTitle}
                    </Typography>
                    <Typography fontSize={{
                        dt: "p1"
                    }} css={{
                        marginTop: '12px',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <svg css={{
                            marginRight: '8px'
                        }} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2934_17743)">
                                <path d="M0.714844 10.4996C0.714844 5.37128 4.87232 1.21387 10.0007 1.21387C15.1291 1.21387 19.2863 5.37128 19.2863 10.4996C19.2863 15.6279 15.1291 19.7853 10.0007 19.7853C4.87231 19.7853 0.714844 15.6279 0.714844 10.4996Z" stroke="currentColor" strokMiterlimit="10"/>
                                <path d="M5 11.1364L8.33333 14.7727L15 7.5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_2934_17743">
                                    <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>
                            {toast.type !== "GenericToast" && (
                                <>was {toast.type.startsWith("Remove") ? "removed from" : "added to"}{' '}{toast.type === "CompareToast" ? "Compare" : "your cart"}</>
                            )}
                            {toast.type === "GenericToast" && (
                                <>{toast.actionText}</>
                            )}
                        </span>
                        {toast.type === "CompareToast" && (
                            <Typography as="button" fontSize={{
                                dt: 'p1'
                            }} fontWeight="medium" css={{
                                color: colors.neutral10,
                                textDecoration: "underline",
                                marginLeft: '16px'
                            }} onClick={() => {
                                setIsOpen(true)
                                _setToasts([])
                            }}>
                                View Now
                            </Typography>
                        )}
                        {(toast.type === "FreeSampleToast" || toast.type === "CartToast") && (
                            <Typography as={Link} fontSize={{
                                dt: 'p1'
                            }} fontWeight="medium" css={{
                                color: colors.shadesWhite,
                                textDecoration: "underline",
                                marginLeft: '16px'
                            }} onClick={() => {
                                _setToasts([])
                            }} to="/cart">
                                View Cart
                            </Typography>
                        )}
                    </Typography>
                </ToastPrimitive.Root>
            ))}
            <ToastPrimitive.Viewport css={{
                position: 'fixed',
                top: '64px',
                right: '28px',
                display: 'flex',
                flexDirection: 'column',
                "&>*:not(style)~*:not(style)": {
                    marginTop: "10px"
                },
                width: '330px',
                [breakpoints.mb]: {
                    width: 'calc(100% - 32px)',
                    right: '16px',
                },
                maxWidth: '100vw',
                margin: 0,
                listStyle: 'none',
                zIndex: 2147483647,
                outline: 'none',
            }} />
        </ToastPrimitive.Provider>
    )
}

export default ToastContext

export const useToastContext = () => React.useContext(Ctx);
